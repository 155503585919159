import {
  collection,
  deleteDoc,
  doc,
  endAt,
  getDocs,
  orderBy,
  query,
  startAt,
  where,
  writeBatch
} from 'firebase/firestore';
import { db } from 'lib/firebase';
import { useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import { toaster } from '../components/ui/toaster';

function cleanInterestText(text) {
  if (!text) return text;

  // Remove non-alphanum, whitespace and punctuation (apostrophe and dash) and trim
  let outText = text.replace(/[^A-Za-z0-9'-\W]/g, '').trim();

  // Replace non-alphanums at the beginning and end
  outText = outText.replace(/[^A-Za-z0-9]*/, '').replace(/[^a-z0-9]*$/, '');

  return outText;
}

// Recursively iterate over child IDs of an interest and return the actual interest
function recursivelyArrangeChildInterests(interestMap, interest) {
  let childInterests = [];

  if (interest['children'] && interest['children'].length > 0) {
    for (let childId of interest['children']) {
      if (typeof childId != 'string') {
        childInterests.push(childId);
      }

      let child = interestMap[childId];

      if (child) {
        if (child['children'] && child['children'].length > 0) {
          let childInterests = recursivelyArrangeChildInterests(
            interestMap,
            child
          );
          child['children'] = childInterests;
        }
        childInterests.push(child);
      }
    }
  }
  return childInterests;
}

export function useInterests({
  text,
  ids,
  hierarchical = true,
  selectableOnly = true
}) {
  let clauses = [];
  let constraints = [];

  if (selectableOnly) {
    clauses.push(where('isSelectable', '==', true));
  }

  if (ids && ids.length > 0) {
    clauses.push(where('id', 'in', ids));
  }

  let interestText = cleanInterestText(text);
  if (interestText) {
    constraints = [startAt(interestText), endAt(interestText + '\uf8ff')];
  }

  const q = query(
    collection(db, 'interests'),
    ...clauses,
    orderBy('text', 'asc'),
    ...constraints
  );
  const [interests, isLoading, error] = useCollectionData(q);
  if (error) throw error;

  let result = interests;
  if (!isLoading && interests) {
    if (hierarchical) {
      // Arrange data
      let interestMap = {};
      let arrangedInterests = [];

      // First build the interest map (there's probably a more idiomatic way to do this, but oh well...)
      for (let interest of interests) {
        interestMap[interest['id']] = interest;
      }

      // Next, recursively arrange the interest hierarchy
      for (let interest of interests) {
        if (!interest['parentId']) {
          let childInterests = recursivelyArrangeChildInterests(
            interestMap,
            interest
          );
          interest['children'] = childInterests;
          arrangedInterests.push(interest);
        }
      }

      result = arrangedInterests;
    }
  }

  return { interests: result, isLoading };
}

export function useDeleteInterest(id) {
  const [isLoading, setLoading] = useState(false);

  async function deleteInterest() {
    // First, get the full list of user interests based on this interest

    // !!! TODO: Also need to remove this interest from tags

    let q = query(
      collection(db, 'user_interests'),
      where('interestId', '==', id)
    );
    let userInterestsQuerySnapshot = await getDocs(q);
    let userInterestsCount = userInterestsQuerySnapshot.docs.length;

    const res =
      userInterestsCount > 0
        ? window.confirm(
            `There are ${userInterestsCount} users with this interest. Are you sure you want to delete it?`
          )
        : true;
    if (res) {
      setLoading(true);

      // Delete all user interests with this interest
      let batch = writeBatch(db);
      userInterestsQuerySnapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();

      const docRef = doc(db, 'interests', id);
      await deleteDoc(docRef);

      toaster.create({
        title: 'Interest deleted',
        type: 'success',
        duration: 5000
      });

      setLoading(false);
    }
  }
  return { deleteInterest, isLoading };
}
