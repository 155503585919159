import { Flex, IconButton, Spinner } from '@chakra-ui/react';
import { FaRegComment, FaComment, FaTrash, FaFilePen } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

import { APP } from 'lib/routes';
import { useDeletePost } from 'hooks/posts';
import { useComments } from 'hooks/comments';
import { useAuth } from 'hooks/auth';

import { Tooltip } from '../../components/ui/tooltip';

export default function Actions({ post }) {
  const { id, uid } = post;
  const { user: authUser, isLoading: authLoading } = useAuth();
  const { deletePost, isLoading: deleteLoading } = useDeletePost(id);
  const { comments, isLoading: commentsLoading } = useComments({ postId: id });

  if (!comments || deleteLoading || commentsLoading) return <Spinner />;

  return (
    <Flex p="2">
      <Flex alignItems="center">
        <IconButton
          as={Link}
          to={`${APP}/comments/${id}`}
          isLoading={commentsLoading}
          size="md"
          colorPalette="themeColor"
          variant="ghost"
          isRound
        >
          {comments.length > 0 ? <FaComment /> : <FaRegComment />}
        </IconButton>
        {comments.length}
      </Flex>

      {!authLoading && authUser.id === uid && deletePost ? (
        <Tooltip label="Delete post">
          <IconButton
            onClick={deletePost}
            isLoading={deleteLoading}
            ml="auto"
            size="md"
            colorPalette="red"
            variant="ghost"
            isRound
          >
            <FaTrash />
          </IconButton>
        </Tooltip>
      ) : (
        ''
      )}
      {!authLoading && authUser.id === uid ? (
        <IconButton
          as={Link}
          to={`${APP}/post/${id}`}
          isLoading={false}
          size="md"
          colorPalette="themeColor"
          variant="ghost"
          isRound
        >
          <FaFilePen />
        </IconButton>
      ) : (
        ''
      )}
    </Flex>
  );
}
