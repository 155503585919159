import { v4 as uuidv4 } from 'uuid';
import {
  collection,
  deleteDoc,
  doc,
  endAt,
  orderBy,
  query,
  setDoc,
  startAt
} from 'firebase/firestore';
import { db } from 'lib/firebase';
import { useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import { toaster } from '../components/ui/toaster';

export function useAddInvite() {
  const [isLoading, setLoading] = useState(false);

  async function addInvite(email, uid) {
    setLoading(true);
    const id = uuidv4();
    const docRef = doc(db, 'invites', id);
    await setDoc(docRef, {
      email,
      id,
      uid,
      createDate: Date.now()
    });

    toaster.create({
      title: 'Invite added',
      type: 'success',
      duration: 5000
    });
    setLoading(false);
  }

  return { addInvite, isLoading };
}

export function useInvites(email) {
  let constraints = [];
  if (email) {
    constraints = [startAt(email), endAt(email + '\uf8ff')];
  }
  const q = query(
    collection(db, 'invites'),
    orderBy('email', 'asc'),
    ...constraints
  );
  const [invites, isLoading, error] = useCollectionData(q);
  if (error) throw error;
  return { invites, isLoading };
}

export function useDeleteInvite(id, email) {
  const [isLoading, setLoading] = useState(false);

  async function deleteInvite() {
    const res = window.confirm(
      `Are you sure you want to delete the invite for ${email}?`
    );
    if (res) {
      setLoading(true);

      const docRef = doc(db, 'invites', id);
      await deleteDoc(docRef);

      toaster.create({
        title: 'Invite deleted',
        type: 'success',
        duration: 5000
      });

      setLoading(false);
    }
  }
  return { deleteInvite, isLoading };
}
