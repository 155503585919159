import { useUploads } from 'hooks/uploads';

import {
  Box,
  Button,
  Spinner,
  Text,
  VStack,
  FileUpload as ChakraFileUpload
} from '@chakra-ui/react';

import {
  FileInput,
  FileUploadClearTrigger,
  //FileUploadDropzone,
  FileUploadLabel,
  FileUploadRoot
} from '../../components/ui/file-upload';

import { CloseButton } from '../../components/ui/close-button';
import { InputGroup } from '../../components/ui/input-group';

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAddVideo } from 'hooks/posts';

import { FaUpload } from 'react-icons/fa6';

const MAX_FILE_SIZE = 500; // Size in MB
const UPLOAD_MIMETYPES = ['video/*'];
//const UPLOAD_MIMETYPES = ['text/*'];

export default function VideoFileUpload(props) {
  let { authUser, authToken, onVideoUploaded, ...rest } = props;
  const { register, handleSubmit, reset } = useForm();
  const { addVideo } = useAddVideo();
  const [errorText, setErrorText] = useState('');
  const [isUploading, setUploading] = useState(false);

  // Set up upload handler
  onVideoUploaded = onVideoUploaded
    ? onVideoUploaded
    : (videoId) => {
        console.log(videoId);
      };

  const {
    uploadUrl,
    uploadToken,
    isLoading: uploadLoading,
    error: uploadError
  } = useUploads(authToken);

  function validateVideoFile(file) {
    const fsMb = file.size / (1024 * 1024);
    if (fsMb > MAX_FILE_SIZE) {
      setErrorText(
        `Sorry, that file is too large. Max size: ${MAX_FILE_SIZE}mb`
      );
      return ['FILE_TOO_LARGE'];
    }
    setErrorText('');
    return null;
  }

  function handleUploadVideo(data) {
    // Revalidate file size
    if (data.file.length > 0) {
      if (validateVideoFile(data.file[0]) !== null) {
        // If validation returned any errors, break out
        return;
      }
    }

    setUploading(true);
    async function upload(data) {
      let videoId = await addVideo({
        uid: authUser.id,
        ...data
      });
      reset();
      setUploading(false);

      return onVideoUploaded(videoId);
    }
    upload(data);
  }

  if (uploadError) {
    return 'Error rendering upload component';
  } else {
    return (
      <Box maxW="800px" py="5" alignItems="left">
        <form onSubmit={handleSubmit(handleUploadVideo)}>
          <VStack align="left">
            <FileUploadRoot
              accept={UPLOAD_MIMETYPES}
              validate={validateVideoFile}
              maxFiles={1}
              {...rest}
              {...register('file', {
                required: true
              })}
            >
              {/*
              // This component *ALMOST* works, but on submit data.file is
              // empty, where as with <FileInput> it contains the file. 
              <FileUploadDropzone
                w="full"
                label="Drag and drop here to upload"
                description={`Video files up to ${MAX_FILE_SIZE}MB`}
              />
              */}

              {!uploadLoading ? (
                <>
                  <ChakraFileUpload.HiddenInput
                    type="hidden"
                    id="uploadUrl"
                    name="uploadUrl"
                    value={uploadUrl || ''}
                    {...register('uploadUrl', { required: true })}
                  />
                  <ChakraFileUpload.HiddenInput
                    type="hidden"
                    id="uploadToken"
                    name="uploadToken"
                    value={uploadToken || ''}
                    {...register('uploadToken', { required: true })}
                  />
                </>
              ) : (
                ''
              )}

              <InputGroup
                w="full"
                startElement={<FaUpload />}
                endElement={
                  <FileUploadClearTrigger asChild>
                    <CloseButton
                      me="-1"
                      size="xs"
                      variant="plain"
                      focusVisibleRing="inside"
                      focusRingWidth="2px"
                      pointerEvents="auto"
                    />
                  </FileUploadClearTrigger>
                }
              >
                <FileInput />
              </InputGroup>
              <FileUploadLabel color="red">{errorText}</FileUploadLabel>
            </FileUploadRoot>
            {!isUploading ? (
              <Button colorPalette="themeColor" maxWidth="300px" type="submit">
                Upload
              </Button>
            ) : (
              <>
                <Spinner />
                <Text>Uploading, please don't close or refresh.</Text>
              </>
            )}
          </VStack>
        </form>
      </Box>
    );
  }
}
