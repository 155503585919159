import { Box, Flex, Image, Link, Text } from '@chakra-ui/react';

import { FaChevronDown } from 'react-icons/fa6';
import { Link as RouterLink } from 'react-router-dom';

import {
  MenuContent,
  MenuRoot,
  MenuItem,
  MenuItemGroup,
  MenuTrigger
} from '../../components/ui/menu';

import { useColorMode, ColorModeButton } from '../../components/ui/color-mode';
import { Button } from '../../components/ui/button';

import {
  APP_DASHBOARD,
  APP_POST_NEW_START,
  APP_USERS,
  ADMIN_INTERESTS,
  ADMIN_INVITES,
  ADMIN_TAGS
} from 'lib/routes';
import { useLogout } from 'hooks/auth';

export default function Navbar(props) {
  const { isAdmin } = props;
  const { logout, isLoading } = useLogout();

  const { colorMode } = useColorMode();

  let admin = '';
  if (isAdmin) {
    admin = (
      <>
        <Text marginX="10px" fontWeight="normal">
          |
        </Text>
        <MenuRoot>
          <MenuTrigger as={Link} color="themeColor">
            Admin
            <FaChevronDown />
          </MenuTrigger>
          <MenuContent>
            <MenuItemGroup>
              <MenuItem color="themeColor" as={RouterLink} to={ADMIN_INTERESTS}>
                Interests
              </MenuItem>
              <MenuItem color="themeColor" as={RouterLink} to={ADMIN_INVITES}>
                Invites
              </MenuItem>
              <MenuItem color="themeColor" as={RouterLink} to={ADMIN_TAGS}>
                Tags
              </MenuItem>
            </MenuItemGroup>
          </MenuContent>
        </MenuRoot>
      </>
    );
  }

  return (
    <Flex
      shadow="sm"
      pos="fixed"
      width="full"
      borderTop="6px solid"
      borderTopColor="themeColor"
      height="16"
      zIndex="3"
      bg="navbarBackground"
      textStyle="nearNavbar"
    >
      <Image
        src={colorMode === 'dark' ? '/logo-white.png' : '/logo-black.png'}
        borderRadius="full"
        minHeight="50px"
        p="10px"
      />

      <Flex px="8" w="full" align="center" maxW="1200px" justify="center">
        <Link
          color="themeColor"
          as={RouterLink}
          to={APP_DASHBOARD}
          boxShadow="none !important"
        >
          Home
        </Link>
        <Text marginX="10px" fontWeight="normal">
          |
        </Text>
        <Link color="themeColor" as={RouterLink} to={APP_POST_NEW_START}>
          New Post
        </Link>
        <Text marginX="10px" fontWeight="normal">
          |
        </Text>
        <Link color="themeColor" as={RouterLink} to={APP_USERS}>
          Search Users
        </Link>
        {admin}
        <Box ml="auto" mr="20px" textStyle="nearSlogan">
          where spatial meets social.
        </Box>
        <ColorModeButton />
        <Button
          colorPalette="themeColor"
          size="sm"
          onClick={logout}
          isLoading={isLoading}
        >
          Logout
        </Button>
      </Flex>
    </Flex>
  );
}
