import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import {
  FaPeopleGroup,
  FaBinoculars,
  FaRegHandshake,
  FaCircleDollarToSlot
} from 'react-icons/fa6';
import Header from './Header';
import Actions from './Actions';
import { ConnectionType } from 'lib/enums';
import { usePostTags } from 'hooks/posts';

import { Tag } from '../../components/ui/tag';

export default function Post({ post }) {
  const { visibility, lengthSeconds: length, type, views } = post;

  const { postTags } = usePostTags(post.id);

  // Default visibility to everyone
  let visibilityIcon = <FaPeopleGroup size={28} color="themeColor" />;
  if (visibility === ConnectionType.Follow) {
    visibilityIcon = <FaBinoculars size={28} color="themeColor" />;
  } else if (visibility === ConnectionType.Friend) {
    visibilityIcon = <FaRegHandshake size={28} color="themeColor" />;
  } else if (visibility === ConnectionType.Benefactor) {
    visibilityIcon = <FaCircleDollarToSlot size={28} color="themeColor" />;
  }

  return (
    <Box p="2" maxW="800px" textAlign="left">
      <Box border="2px solid" borderColor="containerBorder" borderRadius="sm">
        <Header post={post} />
        <Box p="2" minH="100px" bg="containerBackground">
          <Flex>
            <Flex marginLeft="auto" wrap="wrap">
              {postTags
                ? postTags.map((tag) => (
                    <Tag
                      size="md"
                      marginLeft="2px"
                      key={tag.text}
                      borderRadius="full"
                      variant="solid"
                      colorPalette="themeColor"
                    >
                      #{tag.text}
                    </Tag>
                  ))
                : ''}
            </Flex>
          </Flex>
          <Flex>
            <HStack>
              <Text fontSize="md">Type</Text>
              <Text>{type.toUpperCase()}</Text>
            </HStack>
            <HStack marginLeft="auto">
              <Text fontSize="md">Visibility</Text>
              {visibilityIcon}
            </HStack>
          </Flex>
          <Flex>
            <HStack>
              <Text fontSize="md">Length</Text>
              <Text>
                {length / 60}m {length % 60}s
              </Text>
            </HStack>
            <HStack marginLeft="auto">
              <Text fontSize="md">Views</Text>
              <Text>{views}</Text>
            </HStack>
          </Flex>
          <Actions post={post} />
        </Box>
      </Box>
    </Box>
  );
}
