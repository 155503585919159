import { v4 as uuidv4 } from 'uuid';
import {
  and,
  collection,
  deleteDoc,
  doc,
  endAt,
  getDoc,
  getDocs,
  or,
  orderBy,
  query,
  setDoc,
  startAt,
  updateDoc,
  where,
  writeBatch
} from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { db, storage } from 'lib/firebase';
import { useEffect, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useNavigate } from 'react-router-dom';

import { toaster } from '../components/ui/toaster';

export function useUser(uid) {
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    setLoading(true);

    async function fetchUser(uid) {
      const ref = doc(db, 'users', uid);
      const docSnap = await getDoc(ref);
      setUser(docSnap.data());
      setLoading(false);
    }

    if (!uid) {
      setLoading(false);
    } else {
      fetchUser(uid);
    }
  }, [uid]);

  return { user, isLoading };
}

export function useUsers({ filterString }) {
  // Get list of users, filtered by user name or tags

  // Search for users by tag, start by loading tags by the filter string
  const [userTags, userTagsLoading] = useCollectionData(
    query(
      collection(db, 'user_tags'),
      orderBy('text', 'asc'),
      startAt(filterString),
      endAt(filterString + '\uf8ff')
    )
  );

  let taggedUidsConstraints = [];
  let taggedUids = [];
  if (filterString && !userTagsLoading && userTags && userTags.length > 0) {
    taggedUids = userTags.map((tag) => tag.uid);
    taggedUidsConstraints.push(where('id', 'in', taggedUids));
  }

  // Now search for users by name. Default to empty constraints if no filter provided so we will get all users.
  let usernameConstraints = [];
  if (filterString) {
    usernameConstraints = [
      and(
        where('username', '>=', filterString),
        where('username', '<=', filterString + '\uf8ff')
      )
    ];
  }

  let queryConstraints = [];
  if (taggedUidsConstraints && taggedUidsConstraints.length > 0) {
    queryConstraints = [or(...usernameConstraints, ...taggedUidsConstraints)];
  } else {
    queryConstraints = usernameConstraints;
  }
  const [users, usersLoading] = useCollectionData(
    query(
      collection(db, 'users'),
      ...queryConstraints,
      orderBy('username', 'asc')
    )
  );

  return {
    users,
    isLoading: usersLoading
  };
}

export function useDisableUser(uid, isDisabled) {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function updateDisabled() {
    setLoading(true);

    const docRef = doc(db, 'users', uid);
    await updateDoc(docRef, { isDisabled });

    toaster.create({
      title: `${isDisabled ? 'Disabled' : 'Enabled'} user`,
      type: 'success',
      duration: 5000
    });

    setLoading(false);

    navigate(0);
  }
  return {
    updateDisabled,
    isLoading
  };
}

export function useUpdateUser(uid) {
  const [isLoading, setLoading] = useState(false);

  async function updateUser(props) {
    setLoading(true);

    const docRef = doc(db, 'users', uid);
    let updates = {};
    if (props.description) {
      updates['description'] = props.description;
    }

    await updateDoc(docRef, updates);

    toaster.create({
      title: 'Updated user profile',
      type: 'success',
      duration: 5000
    });

    setLoading(false);
  }
  return {
    updateUser,
    isLoading
  };
}

export function useUpdateAvatar(uid) {
  const [isLoading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  async function updateAvatar() {
    if (!file) {
      toaster.create({
        title: 'No file selected',
        description: 'Please select a file to upload',
        type: 'error',
        duration: 5000
      });

      return;
    }

    setLoading(true);

    const fileRef = ref(storage, `avatars/${uid}`);

    await uploadBytes(fileRef, file);

    const avatarURL = await getDownloadURL(fileRef);

    const docRef = doc(db, 'users', uid);
    await updateDoc(docRef, { avatar: avatarURL });

    toaster.create({
      title: 'Updated avatar',
      type: 'success',
      duration: 5000
    });

    setLoading(false);

    navigate(0);
  }
  return {
    setFile,
    updateAvatar,
    isLoading,
    fileURL: file && URL.createObjectURL(file)
  };
}

export function useUserInterests(uid) {
  const q = query(collection(db, 'user_interests'), where('uid', '==', uid));
  const [userInterests, isLoading, error] = useCollectionData(q);
  if (error) throw error;

  return { userInterests, isLoading };
}

export function useAddUserInterest(uid) {
  const [isLoading, setLoading] = useState(false);

  async function addUserInterest(interestId) {
    setLoading(true);

    let id = uuidv4();
    let docRef = doc(db, 'user_interests', id);
    await setDoc(docRef, {
      interestId: interestId,
      id,
      uid,
      createDate: Date.now()
    });
    setLoading(false);
  }

  return { addUserInterest, isLoading };
}

export function useRemoveUserInterest(uid) {
  const [isLoading, setLoading] = useState(false);

  async function removeUserInterest(userInterest) {
    setLoading(true);

    // Delete the user interest
    const docRef = doc(db, 'user_interests', userInterest.id);
    await deleteDoc(docRef);

    setLoading(false);
  }

  async function removeUserInterestByInterestId(interestId) {
    setLoading(true);

    // First, get the full list of user interests based on this interest
    let q = query(
      collection(db, 'user_interests'),
      where('uid', '==', uid),
      where('interestId', '==', interestId)
    );
    let userInterestsQuerySnapshot = await getDocs(q);
    setLoading(true);

    // Delete all post tags with this tag
    let batch = writeBatch(db);
    userInterestsQuerySnapshot.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });
    await batch.commit();

    setLoading(false);
  }

  return { removeUserInterest, removeUserInterestByInterestId, isLoading };
}

export function useChannels(uid) {
  const [isLoading, setLoading] = useState(false);
  const [channels, setChannels] = useState([]);

  function fetchChannels() {
    setLoading(true);
    let q = query(
      collection(db, 'channels'),
      where('uid', '==', uid),
      orderBy('name', 'asc')
    );

    getDocs(q)
      .then((snapshot) => {
        let channels = [];
        snapshot.forEach((doc) => {
          let channel = doc.data();
          channels.push(channel);
        });

        // Fetch interests under each channel
        // Track the number of channels so we know once all their interests have been gathered
        let channelCount = channels.length;
        return new Promise((resolve, reject) => {
          for (let channel of channels) {
            let q = query(
              collection(db, 'interests'),
              where('id', 'in', channel['interests'])
            );
            getDocs(q).then((snapshot) => {
              channel['interests'] = [];
              snapshot.forEach((doc) => {
                channel['interests'].push(doc.data());
              });
              channelCount -= 1;
              if (channelCount === 0) {
                resolve(channels);
              }
            });
          }
        });
      })
      .then((channels) => {
        setChannels(channels);
        setLoading(false);
      });
  }

  return { channels, isLoading, fetchChannels };
}
