import { Box, Spinner, Table } from '@chakra-ui/react';

import {
  AccordionItem,
  AccordionItemContent,
  AccordionItemTrigger,
  AccordionRoot
} from '../ui/accordion';

import { Switch } from '../ui/switch';

import { useInterests } from 'hooks/interests';

import { useState } from 'react';

export default function InterestTree(params) {
  const {
    selectable = false,
    editable = false,
    preSelected = [],
    onSelectionUpdated
  } = params;

  // preSelected will be a list of interest IDs, but we want to maintain a
  // map of interests by id and their selection state to bind to our switches
  let selectionMap = preSelected.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});
  const [selected, setSelected] = useState(selectionMap);
  const { interests, isLoading } = useInterests({
    hierarchical: true,
    selectableOnly: true
  });

  function selectionChanged(id, checked) {
    console.log(`Changed: ${id}, ${checked}, ${editable}`);
    if (editable) {
      selected[id] = checked;
      setSelected(selected);
      if (onSelectionUpdated) {
        onSelectionUpdated(id, checked);
      }
    }
  }

  if (!interests || isLoading) return <Spinner />;

  return (
    <Box maxWidth="800px" ml={2}>
      <AccordionRoot multiple collapsible size="lg">
        {interests.map((interest, index) => (
          <InterestTreeBranch
            key={index}
            interest={interest}
            indent={2}
            selectable={selectable}
            editable={editable}
            selected={selected}
            selectionChanged={selectionChanged}
          />
        ))}
      </AccordionRoot>
    </Box>
  );
}

// Render either a accordion item or an additional accordion root with SelectableInterestTreeBranch's inside
function InterestTreeBranch(params) {
  const { interest, indent, selectable, editable, selected, selectionChanged } =
    params;

  if (interest['children'] && interest['children'].length > 0) {
    return (
      <AccordionItem key={interest['id']} value={interest['id']} border="none">
        <AccordionItemTrigger>
          <Table.Root>
            <Table.Body>
              <Table.Row>
                <Table.Cell bgColor="nearBackground">
                  {interest['emoji']} {interest['displayText']}
                </Table.Cell>
                <Table.Cell textAlign="end" bgColor="nearBackground">
                  {selectable ? (
                    <Switch
                      checked={selected[interest['id']]}
                      disabled={!editable}
                      onCheckedChange={(p) => {
                        selectionChanged(interest['id'], p.checked);
                      }}
                      colorPalette="themeColor"
                    />
                  ) : (
                    ''
                  )}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table.Root>
        </AccordionItemTrigger>
        <AccordionItemContent>
          <Box ml={2 * indent}>
            <AccordionRoot multiple collapsible size="lg">
              {interest['children'].map((child, index) => (
                <InterestTreeBranch
                  key={index}
                  interest={child}
                  indent={2 * indent}
                  selectable={selectable}
                  editable={editable}
                  selected={selected}
                  selectionChanged={selectionChanged}
                />
              ))}
            </AccordionRoot>
          </Box>
        </AccordionItemContent>
      </AccordionItem>
    );
  } else {
    return (
      <AccordionItem key={interest['id']} value={interest['id']} border="none">
        <Table.Root>
          <Table.Body>
            <Table.Row>
              <Table.Cell bgColor="nearBackground">
                {interest['emoji']} {interest['displayText']}
              </Table.Cell>
              <Table.Cell textAlign="end" bgColor="nearBackground">
                {selectable ? (
                  <Switch
                    checked={selected[interest['id']]}
                    disabled={!editable}
                    onCheckedChange={(p) => {
                      selectionChanged(interest['id'], p.checked);
                    }}
                    colorPalette="themeColor"
                  />
                ) : (
                  ''
                )}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table.Root>
      </AccordionItem>
    );
  }
}
