import { Accordion, HStack } from '@chakra-ui/react';
import * as React from 'react';
import { LuChevronDown } from 'react-icons/lu';

// The chevrons don't seem to respect theme colors, so doing it this way
import { useColorModeValue } from '../../components/ui/color-mode';

export const AccordionItemTrigger = React.forwardRef(
  function AccordionItemTrigger(props, ref) {
    const { children, indicatorPlacement = 'end', ...rest } = props;

    const chevronColor = useColorModeValue('white', 'gray.800');

    return (
      <Accordion.ItemTrigger {...rest} ref={ref}>
        {indicatorPlacement === 'start' && (
          <Accordion.ItemIndicator rotate={{ base: '-90deg', _open: '0deg' }}>
            <LuChevronDown color={chevronColor} />
          </Accordion.ItemIndicator>
        )}
        <HStack gap="4" flex="1" textAlign="start" width="full">
          {children}
        </HStack>
        {indicatorPlacement === 'end' && (
          <Accordion.ItemIndicator>
            <LuChevronDown color={chevronColor} />
          </Accordion.ItemIndicator>
        )}
      </Accordion.ItemTrigger>
    );
  }
);

export const AccordionItemContent = React.forwardRef(
  function AccordionItemContent(props, ref) {
    return (
      <Accordion.ItemContent>
        <Accordion.ItemBody {...props} ref={ref} />
      </Accordion.ItemContent>
    );
  }
);

export const AccordionRoot = Accordion.Root;
export const AccordionItem = Accordion.Item;
