import { Box, Input, Spinner, VStack } from '@chakra-ui/react';
import { InputGroup } from '../../components/ui/input-group';
import {
  MenuContent,
  MenuItem,
  MenuRoot,
  MenuTrigger
} from '../../components/ui/menu';

import { cleanTagText, useTags } from 'hooks/tags';

import { useState, useRef } from 'react';

import Tag from '../tags/Tag';

let searchTimer = null;

export default function TagSelect(params) {
  const { preSelected, onSelectionsUpdated, maxSelections } = params;
  const { tags: allTags, isLoading } = useTags({});
  const [selected, setSelected] = useState(preSelected || []);
  const inputRef = useRef(null);
  const [searchInput, setSearchInput] = useState('');
  const [searchText, setSearchText] = useState('');
  const { tags: searchTags, isSearchLoading } = useTags({ text: searchText });

  function removeTag(tag) {
    let updatedSelections = selected.filter((t) => {
      return t.id === tag.id ? null : t;
    });
    setSelected(updatedSelections);

    if (onSelectionsUpdated) {
      onSelectionsUpdated(updatedSelections);
    }
  }

  function debounceSearch(text) {
    if (searchTimer) {
      clearTimeout(searchTimer);
    }
    searchTimer = setTimeout(() => {
      searchTimer = null;
      setSearchText(text);
    }, 800);
  }

  function menuItemSelected(event) {
    let updatedSelections = [...selected, Object.assign({}, event.value)];
    setSelected(updatedSelections);
    if (onSelectionsUpdated) {
      onSelectionsUpdated(updatedSelections);
    }
    setSearchText('');
    setSearchInput('');
  }

  // !!! We're calling this on the Menu's onOpenChange
  // !!! which doesn't seem to work when the menu is initially created/opened.
  // !!! Would be really great to solve this so the focus stays in the input.
  function focusInput() {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  function getNewTag() {
    // Check and see if what the user typed exactly matches an existing tag, if not, we will
    // give the option to create it.
    let formattedText = cleanTagText(searchText);

    if (!searchTags) {
      return <></>;
    }

    let filtered = searchTags.filter((t) => {
      return t.text === formattedText ? t : null;
    });
    if (filtered && filtered.length > 0) {
      return <></>;
    } else {
      return (
        <MenuItem value={{ id: null, text: formattedText }} key={formattedText}>
          Add tag: #{formattedText}
        </MenuItem>
      );
    }
  }

  if (!allTags || isLoading) return <Spinner />;

  return (
    <Box maxWidth="800px">
      <VStack align="left">
        <MenuRoot
          onSelect={menuItemSelected}
          onOpenChange={focusInput}
          open={!isSearchLoading && searchTags && searchInput && searchText}
          closeOnSelect
        >
          <MenuTrigger asChild>
            <InputGroup flex="1" startElement="#">
              <Input
                ps="2em"
                pe="0"
                placeholder="Select or add hash tags so people can find your creation!"
                onChange={(event) => {
                  setSearchInput(event.target.value);
                  debounceSearch(event.target.value);
                }}
                value={searchInput}
                ref={inputRef}
                minWidth="200px"
                disabled={maxSelections && selected.length >= maxSelections}
              />
            </InputGroup>
          </MenuTrigger>
          <MenuContent>
            {!isSearchLoading && searchTags && searchInput && searchText ? (
              searchTags.map((tag) => {
                if (!selected.includes(tag)) {
                  return (
                    <MenuItem value={tag} key={tag.id}>
                      #{tag.text}
                    </MenuItem>
                  );
                } else {
                  return <></>;
                }
              })
            ) : (
              <></>
            )}

            {!isSearchLoading && searchInput && searchText ? (
              getNewTag()
            ) : (
              <></>
            )}
          </MenuContent>
        </MenuRoot>

        <Box maxWidth="800px">
          {selected.map((tag) => (
            <Tag key={tag.text} tag={tag} onClose={removeTag} />
          ))}
        </Box>
      </VStack>
    </Box>
  );
}
