import {
  createListCollection,
  Box,
  Button,
  HStack,
  Input,
  Stack,
  Text,
  Textarea
} from '@chakra-ui/react';

import { useState } from 'react';

import { useForm } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';

import {
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText
} from '../../components/ui/select';

import TagSelect from '../tags/TagSelect';

import {
  useAddPostTag,
  usePost,
  usePostTags,
  useRemovePostTag,
  useUpdatePost
} from 'hooks/posts';
import { ConnectionType } from 'lib/enums';
import { Navigate, useParams } from 'react-router-dom';
import { useTags } from 'hooks/tags';
import { useAuth } from 'hooks/auth';

export default function EditPost(params) {
  const { id } = useParams();
  const [submitted, setSubmitted] = useState(false);
  const { register, handleSubmit, reset } = useForm();
  const { updatePost, isLoading: updatePostLoading } = useUpdatePost();
  const { post, isLoading: postLoading } = usePost(id);
  const { user: authUser, isLoading: authUserLoading } = useAuth();
  const [visibility, setVisibility] = useState([]);

  // Post tags
  const { postTags, isLoading: postTagsLoading } = usePostTags(id);
  const { tags: allTags, isLoading: allTagsLoading } = useTags({});
  const { addTag } = useAddPostTag(authUser ? authUser.id : '');
  const { removeTag, isLoading: removePostTagLoading } = useRemovePostTag();

  // Get a filtered list of tags not already selected by the user, to use in the selection list
  // Also create an index of tag ID -> tag
  // Also update the selected state with a list of actual tags (rather than post tags), and
  // save the initial list of tags
  let availableTags = [];
  let initialTags = [];
  let tagIdIndex = {};
  if (!postTagsLoading && postTags && !allTagsLoading && allTags) {
    let postTagIds = postTags.map((postTag) => postTag.tagId);
    for (let tag of allTags) {
      tagIdIndex[tag.id] = tag;
      if (!postTagIds.includes(tag.id)) {
        availableTags.push(tag);
      } else {
        initialTags.push(tag);
      }
    }
  }
  const [selectedTags, setSelectedTags] = useState(initialTags);

  function handleUpdatePost(data) {
    async function update() {
      await updatePost({
        id,
        description: data.description,
        title: data.title,
        visibility: visibility ? visibility[0] : null
      });

      // Update post tags
      // First remove post tags
      for (let tag of initialTags) {
        if (!selectedTags.includes(tag)) {
          let filtered = postTags.filter((pt) => {
            return pt.tagId === tag.id ? pt : null;
          });
          if (filtered && filtered.length > 0) {
            for (let f of filtered) {
              removeTag(f);
            }
          }
        }
      }

      // Next add post tags
      for (let tag of selectedTags) {
        if (!initialTags.includes(tag)) {
          addTag({ id, ...data }, tag);
        }
      }

      reset();
      setSubmitted(true);
    }

    update();
  }

  function updateSelectedTags(selected) {
    console.log(selected);
    setSelectedTags(selected);
  }

  // Putting in this logic so that even when we don't change the visibility selection, it retains it's value
  if (post && post.visibility && visibility.length === 0) {
    setVisibility([post.visibility]);
  }

  const audiences = createListCollection({
    items: Object.entries(ConnectionType).map((kv) => {
      return { label: kv[0], value: kv[1] };
    })
  });

  if (authUserLoading || postLoading) return '';
  return (
    <Box maxW="600px" mx="auto" py="5">
      <form onSubmit={handleSubmit(handleUpdatePost)}>
        <Stack spacing={3}>
          <Input
            size="lg"
            {...register('title', { required: true, value: post.title })}
            placeholder="Title"
          />

          <Textarea
            as={TextareaAutosize}
            resize="none"
            mt="5"
            placeholder={'Description'}
            minHeight="2lh"
            {...register('description', {
              required: true,
              value: post.description
            })}
          />

          <HStack>
            <Text size="lg">Audience</Text>
            <SelectRoot
              collection={audiences}
              onValueChange={(e) => {
                setVisibility(e.value);
              }}
              value={visibility}
            >
              <SelectTrigger>
                <SelectValueText placeholder="Select an audience" />
              </SelectTrigger>
              <SelectContent>
                {audiences.items.map((audience) => (
                  <SelectItem item={audience} key={audience.value}>
                    {audience.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </SelectRoot>
          </HStack>

          <TagSelect
            preSelected={
              postTags
                ? postTags.map((t) => {
                    return tagIdIndex[t.tagId];
                  })
                : []
            }
            onSelectionsUpdated={updateSelectedTags}
            maxSelections={10}
          ></TagSelect>

          <Button
            colorPalette="themeColor"
            type="submit"
            isLoading={updatePostLoading}
            loadingText="Loading..."
          >
            Save
          </Button>
        </Stack>
      </form>

      {submitted ? <Navigate to={`/app/dashboard`} replace={true} /> : ''}
    </Box>
  );
}
